import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeFolder: { id: 0, name: "" },
  data: [],
  originalFolderList: [], // TOD0: deprecate one of data or originalFolderlist
  loader: false,
  isFolderRestored: false, // TOD0: deprecate this
};

export const ViewAllFolderSlice = createSlice({
  name: "Folderview",
  initialState,
  reducers: {
    updateFolderList: (state, action) => {
      state.data = action.payload;
    },
    updateFolderLoader: (state, action) => {
      state.loader = action.payload;
    },
    updateTempFolderList: (state, action) => {
      state.originalFolderList = action.payload;
    },
    setIsFolderRestored: (state, action) => {
      state.isFolderRestored = action.payload;
    },
    updateActiveFolder: (state, action) => {
      state.activeFolder = action.payload;
    },
    resetActiveFolder: state => {
      state.activeFolder = { id: 0, name: "" };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateFolderList,
  updateFolderLoader,
  updateTempFolderList,
  setIsFolderRestored,
  updateActiveFolder,
  resetActiveFolder,
} = ViewAllFolderSlice.actions;

export default ViewAllFolderSlice.reducer;
