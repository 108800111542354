const BASE_UF = "uf/api/v1";
const BASE_MASTER_DATA = "masterdata/api/v1";
const BASE_USER_PROFILE = "userprofile/api/v1";
const SSO_BASE = process.env.REACT_APP_TOKEN_SFDCURL;

export const SSO_URLs = {
  ssoToken: `${SSO_BASE}oauth2/token`,
  ssoAuthorize: `${SSO_BASE}oauth2/authorize`,
  signOutUrl: `${SSO_BASE}auth/idp/oidc/logout`,
};

export const EXTERNAL_URLS = {
  waveProURL: "https://wavepro.dupont.com",
  privacyUrl: "https://www.dupont.com/privacy.html",
  contactDupontUrl: "https://www.dupont.com/water/contact-us.html",
  resourceCenterUrl: "https://www.dupont.com/resource-center.html?BU=water",
  termsConditions: "https://www.dupont.com/legal-notices-and-terms-of-use.html",
  ufConfigurationHelp: "https://watersolutions.showpad.com/share/mQ7eHe3T8KitIs6g9dzAp",
  carouselLink:
    "https://www.dupont.com/brands/integratec-ultrafiltration.html?src=ws_global%20_web_wavepro_wavepro_brandawarness_04.12.2024",
  ultrafiltrationURL: "https://www.dupont.com/water/technologies/ultrafiltration-uf.html",
};

const MASTER_DATA = {
  pumps: `${BASE_MASTER_DATA}/Pumps`,
  cases: `${BASE_MASTER_DATA}/Cases`,
  project: `${BASE_MASTER_DATA}/Projects`,
  caseMgmt: `${BASE_MASTER_DATA}/CaseMgmt`,
  feedWater: `${BASE_MASTER_DATA}/FeedWater`,
  folderList: `${BASE_MASTER_DATA}/FolderList`,
  countriesList: `${BASE_MASTER_DATA}/Country`,
  allProjects: `${BASE_MASTER_DATA}/ProjectAll`,
  projectInfo: `${BASE_MASTER_DATA}/ProjectInfo`,
  projectState: `${BASE_MASTER_DATA}/ProjectState`,
  systemDesign: `${BASE_MASTER_DATA}/SystemDesign`,
  projectStatus: `${BASE_MASTER_DATA}/EntityCheck`,
  subTechnology: `${BASE_MASTER_DATA}/SubTechnology`,
  marketSegment: `${BASE_MASTER_DATA}/MarketSegment`,
  unitOfMeasure: `${BASE_MASTER_DATA}/UnitOfMeassure`,
  recentProjects: `${BASE_MASTER_DATA}/ProjectRecent`,
  defaultCurrency: `${BASE_MASTER_DATA}/DefaultCurrency`,
  projectFavorite: `${BASE_MASTER_DATA}/ProjectFavorite`,
  duplicateProject: `${BASE_MASTER_DATA}/DuplicateProject`,
  unitConversionData: `${BASE_MASTER_DATA}/UnitConversion`,
  chemicalCategory: `${BASE_MASTER_DATA}/ChemicalCategory`,
  defaultTechnology: `${BASE_MASTER_DATA}/DefaultTechnology`,
  applicationVersion: `${BASE_MASTER_DATA}/ApplicationVersion`,
  chemicalAdjustment: `${BASE_MASTER_DATA}/ChemicalAdjustment`,
  unreadNotifications: `${BASE_MASTER_DATA}/UnreadNotification`,
  projectFolderMapping: `${BASE_MASTER_DATA}/ProjectFolderIDMapping`,
  calculateChemicalAdjustment: `${BASE_MASTER_DATA}/CalculateChemicalAdjustment`,
  calculateFeedWaterData: `${BASE_MASTER_DATA}/CalculateFeedWaterData`,
  folder: `${BASE_MASTER_DATA}/Folder`,
  deleteFolder: `${BASE_MASTER_DATA}/DeleteFolder`,
  restoreFolder: `${BASE_MASTER_DATA}/RestoreFolder`,
  permanentDeleteFolder: `${BASE_MASTER_DATA}/PermanentDeleteFolder`,
  restoreProject: `${BASE_MASTER_DATA}/RestoreProject`,
  folderProject: `${BASE_MASTER_DATA}/FolderProject`,
  projectDeleted: `${BASE_MASTER_DATA}/ProjectDeleted`,
  renameProject: `${BASE_MASTER_DATA}/RenameProject`,
  favoriteProject: `${BASE_MASTER_DATA}/FavoriteProject`,
};

const USER_PROFILE = {
  userData: `${BASE_USER_PROFILE}/UserData`,
  ixdUsers: `${BASE_USER_PROFILE}/IXDUsers`,
  importProject: `${BASE_USER_PROFILE}/Import`,
  exportProject: `${BASE_USER_PROFILE}/Export`,
  firstLogin: `${BASE_USER_PROFILE}/FirstLogin`,
  waterLibrary: `${BASE_USER_PROFILE}/WaterLibrary`,
  chemicalLibrary: `${BASE_USER_PROFILE}/ChemicalLibrary`,
  projectCosts: `${BASE_USER_PROFILE}/ChemicalLibrary/ProjectCosts`,
  chemicalLibraryCategory: `${BASE_USER_PROFILE}/ChemicalLibrary/Categories`,
  chemicalLibraryChemicalUsage: `${BASE_USER_PROFILE}/ChemicalLibrary/ChemicalUsage`,
  defaultChemicalPriceAndUtilityCost: `${BASE_USER_PROFILE}/ChemicalLibrary/ProjectCosts/MakeDefault`,
};

const UF = {
  ufConfig: `${BASE_UF}/UFConfig`,
  ufDetails: `${BASE_UF}/UFDetails`,
  designData: `${BASE_UF}/DesignData`,
  ufCalcReport: `${BASE_UF}/UFCalcReport`,
};

const API_URLS = { ...MASTER_DATA, ...USER_PROFILE, ...UF };

export default API_URLS;
