import { useCallback, useState } from "react";
import { Droppable } from "react-drag-and-drop";
import { useDispatch, useSelector } from "react-redux";
import { putRequest } from "src/api";

import API_URLS from "@constants/api.urls";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import useHome from "@hooks/useHome";

import { combineClassNames } from "@utils/appUtils";

import { FolderIcon } from "@components/SVGs";
import WPHeading from "@components/WPHeading";
import { useToastNotification } from "@components/WPToastNotification";

import { setSideMenu, updateActiveMenu } from "@features/menu/SideMenuSlice";

import { updateLoader } from "../CardListSlice";
import { HomeSideMenuStrings } from "../constants/HomeStrings";
import { updateActiveFolder } from "../ViewAllFolderSlice";

import { HomeSideNavIds } from "./HomeSideNav.constants";

const HomeSideNavFolders = () => {
  const dispatch = useDispatch();
  const { breadcrumbData = [] } = useSelector(state => state.leftpanel);
  const { id: selectedFolderID } = useSelector(state => state.Folderview.activeFolder);
  const { originalFolderList } = useSelector(state => state.Folderview);
  const { showSuccessNotification, showErrorNotification } = useToastNotification();
  const { UserId } = useSelector(state => state.userInfo.data) || {};
  const { getHomeListData } = useHome();

  const [dropData, setDropData] = useState([]);

  const onItemDrop = useCallback(data => {
    const items = data.item.split(",");
    setDropData(items);
  }, []);

  const handleDragAndDrop = useCallback(
    async folderID => {
      try {
        const targetFolder = originalFolderList.find(item => item.folderID === folderID);
        const userID = UserId;
        if (dropData[3] === "false") {
          const payload = { userID, folderID, projectID: dropData[0], addProjectToFolder: true };
          const response = await putRequest(API_URLS.projectFolderMapping, payload);
          if (response.status === 200) {
            const message = HomeSideMenuStrings.folderMoved
              .replace("PROJECT_NAME", dropData[1])
              .replace("FOLDER_NAME", targetFolder.folderName);
            getHomeListData();
            showSuccessNotification(message);
          }
        }
      } catch (error) {
        showErrorNotification(APP_TOAST_NOTIFICATION.MOVE_PROJECT_TO_FOLDER_FAILED);
      }
    },
    [dropData, originalFolderList, UserId, dispatch, showSuccessNotification, showErrorNotification],
  );

  const handleMouseMove = useCallback(
    folderID => {
      if (dropData.length > 0) {
        handleDragAndDrop(folderID);
        setDropData([]);
      }
    },
    [dropData, handleDragAndDrop],
  );

  const getBreadcrumbData = folderName => {
    const newBreadcrumbData = [...breadcrumbData];
    const breadcrumbCount = newBreadcrumbData.length;
    if (newBreadcrumbData && newBreadcrumbData[breadcrumbCount - 1].id === "FOLDER_ITEM") {
      newBreadcrumbData.slice(breadcrumbCount - 1, 1);
    }
    newBreadcrumbData.push({ id: "FOLDER_ITEM", label: folderName });
    return newBreadcrumbData;
  };

  const btnFolderProject = useCallback(({ folderID, folderName }) => {
    dispatch(updateActiveFolder({ id: folderID, name: folderName }));
    dispatch(updateLoader(false));
    dispatch(updateActiveMenu(HomeSideNavIds.folderProject));
    const newBreadcrumbData = getBreadcrumbData(folderName);
    dispatch(setSideMenu({ breadcrumbData: newBreadcrumbData }));
  }, []);

  return (
    <div className='side-nav-menu-folder-list'>
      <ul className='list-unstyled folder-list'>
        {originalFolderList?.map(folder => (
          <Droppable types={["item"]} onDrop={onItemDrop} key={`droppable-${folder.folderID}`}>
            <li
              id={`side_nav_folder_item_${folder.folderID}`}
              onMouseOver={() => handleMouseMove(folder.folderID)}
              className={combineClassNames(
                "side-nav-option",
                "text-overflow-ellipsis",
                folder.folderID === selectedFolderID && "side-nav-menu-active",
              )}
              onClick={() => btnFolderProject(folder)}
            >
              <FolderIcon opened={folder.folderID === selectedFolderID} />
              <div className='folder-name-container'>
                <WPHeading secondary size='small' type='default' tooltip={folder.folderName} text={folder.folderName} />
              </div>
            </li>
          </Droppable>
        ))}
      </ul>
    </div>
  );
};

export default HomeSideNavFolders;
