export const HomeStrings = {
  allProjectHeadline: "One stop place with all your projects. Utilise search and filter to find the desire project.",
  favoriteProjectHeadline:
    "The collection have all the projects starred by you. To remove a project from this collection, click on the three-dot menu and remove the project from favorites.",
  deletedProjectHeadline:
    "Note that projects show the date of deletion. The projects can be retained for 30 days, after which they will be permanently deleted.",
  importProjectHeadline: "Here are all the projects imported by you.",
  selectFoldersHeadline: "Select the folders to see the projects under them.",
  allFolders: "All Folders",
  dateCreated: "Date Created",
  lastModified: "Last Modified",
  ascending: "Ascending",
  descending: "Descending",
};

export const HomeSideMenuStrings = {
  home: "Home",
  allProjects: "All Projects",
  favouriteProjects: "Favorite Projects",
  importtedProjects: "Imported Projects",
  deletedProjects: "Deleted",
  folders: "Folders",
  folderMoved: "Project 'PROJECT_NAME' has been moved to 'FOLDER_NAME'",
};
