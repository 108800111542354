import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import API_URLS from "@constants/api.urls";

import WaveProBrandLogo from "@components/WaveProBrandLogo";

import TabletCloseMenuIcon from "@common/icons/TabletCloseMenuIcon";
import { updateTabAvailable } from "@common/ReportIXDSlice";
import { updateTabAvailableForUF } from "@common/ReportUFSlice";

import { useLazyGetAllDataQuery } from "@services/apiConfig";

import {
  resetActiveFolder,
  setIsFolderRestored,
  updateFolderList,
  updateTempFolderList,
} from "@features/home/ViewAllFolderSlice";
import { setSideMenu, updateActiveMenu } from "@features/menu/SideMenuSlice";

import { updateLoader } from "../CardListSlice";

import { FoldersBreadcrumbData, HomeSideNavMenu } from "./HomeSideNav.constants";
import HomeSideNavFolders from "./HomeSideNavFolders";
import HomeSideNavItem from "./HomeSideNavItem";
import StyledHomeSideNav from "./StyledHomeSideNav";

import "./HomeSideNav.scss";

const HomeSideNav = ({ showSideMenu, setShowMenuIcon }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getData, responsedata] = useLazyGetAllDataQuery();

  const UserInfoStore = useSelector(state => state.userInfo.data);
  const { activeMenu } = useSelector(state => state.leftpanel);
  const isFolderRestored = useSelector(state => state.Folderview.isFolderRestored);

  const userID = UserInfoStore ? UserInfoStore.UserId : 1;

  const [isFolderOpened, setIsFolderOpened] = useState(false);
  const [isFolderCreated, setIsFolderCreated] = useState(false);

  useEffect(() => {
    if (responsedata.status !== "fulfilled") {
      fetchFolders();
    } else {
      dispatch(updateFolderList(responsedata.data));
      dispatch(updateTempFolderList(responsedata.data));
    }
  }, [responsedata]);

  useEffect(() => {
    if (isFolderCreated) {
      fetchFolders();
      setIsFolderCreated(false);
      dispatch(setIsFolderRestored(false));
    }
  }, [isFolderCreated]);

  useEffect(() => {
    if (isFolderRestored) {
      fetchFolders();
      dispatch(setIsFolderRestored(false));
    }
  }, [isFolderRestored]);

  useEffect(() => {
    fetchFolders();
  }, [userID]);

  const fetchFolders = () => {
    if (userID) {
      getData(`${API_URLS.folderList}?userID=${userID}`);
    }
  };

  const handleCloseMenu = () => {
    setShowMenuIcon(false);
  };

  const handleNavigate = () => {
    navigate("/home");
    dispatch(updateTabAvailable({ FeedSetup: false, IXD: false }));
    dispatch(updateTabAvailableForUF({ FeedSetup: false, UF: false }));
  };

  const handleMenuItemClick = optionId => {
    dispatch(updateActiveMenu(optionId));
    dispatch(updateLoader(false));
    let breadcrumbData = null;
    if (optionId === "folders") {
      const isFolderView = !isFolderOpened;
      setIsFolderOpened(isFolderView);
      breadcrumbData = FoldersBreadcrumbData;
    }
    dispatch(setSideMenu({ breadcrumbData }));
    dispatch(resetActiveFolder());
  };

  return (
    <>
      <StyledHomeSideNav showSideMenu={showSideMenu} className='home-side-nav-container'>
        {showSideMenu && (
          <div className='tablet_menu_view'>
            <button className='close_icon_btn' onClick={handleCloseMenu}>
              <TabletCloseMenuIcon />
            </button>
            <WaveProBrandLogo handleNavigate={handleNavigate} />
          </div>
        )}
        <div className='project_navigation_menu'>
          <ul className='project_navigation_menu_list'>
            {HomeSideNavMenu.map(menuOption => (
              <HomeSideNavItem
                menuOption={menuOption}
                key={menuOption.id}
                selectedOption={activeMenu}
                onClick={handleMenuItemClick}
                isFolderOpened={isFolderOpened}
              />
            ))}
          </ul>
        </div>
        {isFolderOpened && <HomeSideNavFolders />}
      </StyledHomeSideNav>
    </>
  );
};

export default HomeSideNav;
