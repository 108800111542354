import styled from "styled-components";

import { colors } from "@common/styles/Theme";

import "@common/styles/diodrumFont.css";
import "@common/styles/notoSansFont.css";

const StyledHomeSideNav = styled.div`
  border-width: 0px 0.5px 0.5px 0.5px;
  border-style: solid;
  border-color: ${colors.GreyE1};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  margin-top: ${({ showSideMenu }) => (showSideMenu ? "0px" : "58px")};
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0;
  background-color: #fff;
  min-width: ${({ showSideMenu }) => (showSideMenu ? "350px" : "inherit")};
  width: ${({ showSideMenu }) => (showSideMenu ? "35.5%" : "19%")};

  .tablet_menu_view {
    display: flex;
    padding: 8.5px 10px 15px 15px;
    gap: 15px;
    .close_icon_btn {
      border: none;
      outline: none;
      background-color: transparent;
    }
  }

  .project_navigation_menu {
    .project_navigation_menu_list {
      padding-left: 0px;
      list-style: none;
    }
  }

  @media (max-width: 575px) {
    position: unset;
    display: block;
    height: 100%;
    .side-menu {
      height: 100%;
    }
  }
`;

export default StyledHomeSideNav;
